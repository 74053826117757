<template>
  <div class="info">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="banner">
      <img src="/imgs/us-banner.jpg" alt="">
    </div>


    <!-- 关于我们区块 -->
    <div class="us-con">
      <div class="w">
        <!-- 左侧 -->
        <div class="us-l">
          <h3>ABOUT US</h3>
          <h2>杭州向榕网络科技有限公司</h2>
          <p>
            杭州向榕网络科技有限公司成立于2023年05月25日，注册地位于浙江省杭州市余杭区仓前街道欧美金融城2幢1208-2室，经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；组织文化艺术交流活动；信息技术咨询服务；计算机系统服务；信息系统集成服务；广告制作；广告设计、代理；版权代理；广告发布；项目策划与公关服务；摄像及视频制作服务（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。许可项目：第二类增值电信业务；互联网信息服务；网络文化经营；出版物零售；广播电视节目制作经营（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）。
          </p>
          <!-- <div class="us-btn">查看更多</div> -->
        </div>
        <!-- 右侧 -->
        <div class="us-r">
          <img src="/imgs/us.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "/",
    };
  },
  mounted() {
    document.title = "杭州向榕 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.info {
  width: 100%;
  // background-color: #f0f0f0;

  // banner
  .banner {
    width: 100%;
    height: 400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 关于我们区块
  .us-con {
    width: 100%;
    height: 400px;
    border-bottom: 6px solid $colorZ;
    margin: 40px 0;

    .w {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      box-sizing: border-box;

      // 左侧
      .us-l {
        width: 60%;
        height: 100%;

        h3 {
          font-size: 38px;
          color: #888;
        }

        h2 {
          font-size: 34px;
          margin-top: 10px;
        }

        p {
          font-size: 12px;
          color: #888;
          line-height: 50px;
          margin-top: 15px;
        }

        .us-btn {
          width: 100px;
          height: 40px;
          background-color: $colorZ;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            border-radius: 20px;
          }
        }
      }

      // 右侧
      .us-r {
        width: 40%;
        height: 100%;
        margin-left: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}</style>
