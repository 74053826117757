<template>
  <div class="nav-footer">
  

    <div class="w">
      <!-- 左侧 -->
      <div class="l">
        <h2>网站服务热线</h2>
        <h4>18158649917</h4>

        <p>杭州向榕网络科技有限公司</p>
        <p>邮箱：2441278635@qq.com -- 域名：xiangrongwl.com</p>
        <p>
          地址：浙江省杭州市余杭区仓前街道欧美金融城2幢1208-2室 --
          <a href="https://beian.miit.gov.cn/#/Integrated/index">ICP备案编号：浙ICP备2023016479号-1</a>
        </p>
      </div>
      <!-- 右侧 -->
      <div class="r">
        <p>
          杭州向榕网络科技有限公司成立于2023年05月25日，注册地位于浙江省杭州市余杭区仓前街道欧美金融城2幢1208-2室，经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；组织文化艺术交流活动；信息技术咨询服务；计算机系统服务；信息系统集成服务；广告制作；广告设计、代理；版权代理；广告发布；项目策划与公关服务等。。。
        </p>
      </div>
    </div>

    <div class="f-list">
      <div class="mc">
        <a href="/">网站首页</a>
        <a href="/#/ac1">动漫作品</a>
        <a href="/#/info">关于我们</a>
        <a href="/#/xy">用户协议</a>
        <a href="/#/xy2">未成年人限制条款</a>
        <a href="/#/xy3">纠纷处理</a>
        <br>
        <br>
        <a href="https://www.shdf.gov.cn/shdf/channels/740.html">打黄扫非入口</a>
        <a href="https://www.12377.cn/">举报专区</a>
        <a href="http://cyberpolice.mps.gov.cn/wfjb/">网络110报警服务</a>
        <a href="https://www.12377.cn/">中国互联网举报中心</a>
        <a href="https://www.12377.cn/">网络有害信息举报</a>
        <a href="https://www.12377.cn/node_548446.htm">网络举报APP下载</a>
        <a href="https://jbts.mct.gov.cn/">全国文化和旅游市场网上举报投诉</a>
        <a href="https://www.beian.gov.cn/portal/index.do">经营性网站备案信息</a>
      </div>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-footer {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #7a7979;

  .f-list {
    margin-top: 15px;
    .mc {
      width: 1226px;
      margin: 0 auto;
      a {
        color: #fff;
        font-size: 12px;
        margin: 0 5px;
        text-decoration: none;
      }
    }
  }
  .w {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // 左侧
    .l {
      h2 {
        font-size: 14px;
        font-family: "Microsoft YaHei";
        color: #fff;
      }

      h4 {
        font-size: 28px;
        color: #fff;
        font-weight: bold;
        margin: 10px 0 20px;
      }

      p {
        font-size: 12px;
        color: #fafafa;
        margin: 5px 0;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    // 右侧
    .r {
      width: 50%;
      height: 100%;

      p {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 30px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}</style>
