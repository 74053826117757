<template>
    <div class="info">
      <!-- 导航栏 -->
      <nav-header :href="this.href"></nav-header>
  
      <div class="banner">
        <img src="/imgs/us-banner.jpg" alt="">
      </div>
  
         <!-- 定宽布局 -->
    <div class="w">
      <div class="con-logo">
        <img src="/imgs/logo2.png" alt="">
      </div>
      <!-- 动漫容器 -->
      <div class="con">
        <!-- 循环项 -->
        <div class="item" v-for="(item, index) in this.dataList"
          :key="index"
          >
          <div class="item-img">
            <img :src="item.img" alt="">
          </div>
          <div class="box">
            <h2>{{ item.title }}</h2>
            <h4>{{ item.title }}动漫简介：</h4>
            <p>{{ item.jj }}</p>
            <span>付费观看完整动漫</span>
            <a @click="goDays(item.methods, item.id)">点击观看</a>
          </div>
        </div>
      </div>

     

      <!-- 分页器 -->
      <navigation :pages="pages" :current.sync="pageNo" @navpage="msgListView" style="text-align: center; display: none">
      </navigation>
      <!-- modal 弹框 -->
      <modal title="图片" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
        @cancel="showModal = false">
        <template v-slot:body>
          <img :src="imgUrl" />
        </template>
      </modal>
    </div>

     
    </div>
  </template>
  
  <script>
  import Modal from "../components/Modal.vue";
  import NavHeader from "./../components/NavHeader";
  import navigation from "./../components/navigation";

  export default {
    name: "index",
    data() {
      return {
        state: JSON.parse(window.localStorage.getItem("state")),
        href: "/",
        // 根数据
        dataList: [],
        dataList2: [],
        dataList3: [],
        // 总页数
        pages: 7,
        // 当前页
        pageNo: 1,
        // 点击页数
        curPage: 1,
        // 根据页数获取数据
        my_data: [],
        my_data2: [],
        my_data3: [],
        // 是否显示弹框
        showModal: false,
  
        imgUrl: "",
       
      };
    },
    components: {
      NavHeader,
      navigation,
      Modal,

    },
    mounted() {
      this.init();
      document.title = "杭州向榕";
    },
    methods: {
      // 分页器事件 //根据当前页获取数据
      msgListView(curPage) {
        this.curPage = curPage;
        console.log(this.curPage);
        this.init();
      },
  
      init() {
        // 渲染数据
        this.axios.get("/mock/a1.json").then((res) => {
          console.log(res);
          var data = res.data;
          // 根数据
          this.dataList = data;
        });
  
        this.axios.get("/mock/a2.json").then((res) => {
          var data = res.data;
          this.dataList2 = data;
        });
  
      },
      // 跳转 逻辑 封装
      goDays(methodsWords, type) {
        this[methodsWords](type);
      },
      // 跳转详情页
      goDetails(id) {
        this.$router.push({
          path: "/sound/:" + 1,
          query: { type: id },
        });
      },
      // 收费逻辑
      gopay(id) {
        // if (this.state == 0) {
        //   this.$router.push("/pay");
        //   return;
        // }
        // // 未登录
        // if (this.state !== 0) {
        //   alert("点击登录去购买~");
        //   this.$router.push({ path: "/login", query: { k: 1 } });
        //   return;
        // }
  
        this.$router.push({
          path: "/sound2/:" + 1,
          query: { type: id },
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  @import "./../assets/scss/base.scss";
  @import "./../assets/scss/config.scss";
  @import "./../assets/scss/mixin.scss";
  
  .info {
    width: 100%;
    // background-color: #f0f0f0;
  
    // banner
    .banner {
      width: 100%;
      height: 400px;
      margin-bottom: 7%;
  
      img {
        width: 100%;
        height: 100%;
      }
    }

    .w {
    // 动漫logo
    .con-logo {
      width: 300px;
      height: 80px;
      margin: 10px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    // 动漫容器
    .con {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 20px 0;

      .item {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: space-between;
      background-color: #f0efef;
      margin: 30px 0;

        .item-img {
          width: 39%;
          height: 100%;
          display: block;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .box {
          // width: 49%;
          flex: 1;
          height: 100%;
          padding: 15px;
          box-sizing: border-box;
          h2 {
            font-size: 51px;
            color: #333;
          }

          h4 {
            font-size: 24px;
            margin: 8% 0 2% 0;
          }
          p {
            font-size: 18px;
            line-height: 51px;
          }

          span {
            display: block;
            color: #c60023;
            margin-top: 15%;
            font-size: 16px;
            // cursor: pointer;
          }

          a {
            display: block;
            width: 140px;
            height: 40px;
            background-color: #c60023;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            margin-top: 5%;
          }
        }
      }

    }

    // banner
    .banner {
      width: 100%;
      height: 300px;
      background-color: pink;
      margin: 30px 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  
    // 关于我们区块
    .us-con {
      width: 100%;
      height: 400px;
      border-bottom: 6px solid $colorZ;
      margin: 40px 0;
  
      .w {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;
  
        // 左侧
        .us-l {
          width: 60%;
          height: 100%;
  
          h3 {
            font-size: 38px;
            color: #888;
          }
  
          h2 {
            font-size: 34px;
            margin-top: 10px;
          }
  
          p {
            font-size: 12px;
            color: #888;
            line-height: 50px;
            margin-top: 15px;
          }
  
          .us-btn {
            width: 100px;
            height: 40px;
            background-color: $colorZ;
            color: #fff;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            transition: all 0.4s;
  
            &:hover {
              border-radius: 20px;
            }
          }
        }
  
        // 右侧
        .us-r {
          width: 40%;
          height: 100%;
          margin-left: 30px;
  
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }</style>
  